.answer {
    input {
        margin-right: 10px;
    }
}
.question-container {
    display: flex;
    flex: 1;
    align-items: center;

    .question {
        padding: 10px;
        font-size: var(--fs-body);
        display: flex;
        align-items: center;

        .question-number {
            display: inline-flex;
            margin-right: 10px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            //background: #fff;
            border: 1px solid var(--ion-color-primary-pale);
        }
    }
}

.answers {
    margin-bottom: 10px;
    ion-item {
        ion-checkbox {
            margin: 16px;
        }
    }
}

.results {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 100%;
    @media (min-width: 768px) {
        max-width: 66%;
    }
    @media (min-width: 992px) {
        max-width: 50%;
    }
    @media (min-width: 1200px) {
        max-width: 33%;
    }

    .result {
        border-radius: 10px;
        width: 40px;
        height: 10px;
        background: rgba(255,255,255,.25);
        &.active {
            background: rgba(255,255,255,.75);
        }

        &.answered {
            opacity: 1;
            font-weight: bold;
            background: var(--ion-color-danger);
            color: white;
            &.good-answer {
                background: var(--ion-color-success);
            }
        }
    }
}

.result-text {
    padding: var(--ion-margin);
    p,
    span {
        // font-family: "Roboto", "Arial", sans-serif !important;
        // font-size: var(--fs-body) !important;
        // color: #030303 !important;
        // margin: 0 !important;
        // padding: 0 !important;
        // background: none !important;
    }
}

.finish {
    .buttons {
        margin: 30px auto 0 auto;
        max-width: 100%;
        @media (min-width: 768px) {
            max-width: 66%;
        }
        @media (min-width: 992px) {
            max-width: 50%;
        }
        @media (min-width: 1200px) {
            max-width: 33%;
        }
    }
}
