.watched-videos {
    display: grid;
    grid-template-columns: max-content 1fr 32px max-content;
    grid-row-gap: 8px;

    svg,
    .watch-index {
        align-self: center;
        justify-self: end;
        color: var(--ion-color-yellow);
    }
    .watch-index {
        padding-left: 10px;
    }
    svg {
        height: auto;
        max-width: 32px;
    }
}
