.feedback-popover {
    background: rgba(0, 0, 0, 0.5);
    ion-title {
        @media (max-width: 767px) {
            text-align: left;
            padding: 0;
            padding-left: var(--ion-margin);
        }
    }
    .twb-container {
        color: var(--ion-color-dark);
    }
    .popover-content {
        overflow: hidden;
        width: calc(100vw - 5%);
        text-align: center;
        padding-bottom: 10px;
        top: 0 !important;
        bottom: 0;
        left: 0 !important;
        right: 0;
        margin: auto;
        @media (min-width: 768px) {
            width: 50vw;
            height: 60vh;
        }
        .scroll-y {
            overflow: scroll;
        }
        img {
            max-width: 33%;
        }
        textarea {
            width: 90%;
            margin: 0 auto 10px;
        }
    }
}
