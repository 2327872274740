#sticky-menu {
    display: flex;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    background: #ffffff;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;

    --color: var(--ion-tab-bar-color, var(--ion-color-step-400, #999999));
    --color-selected: var(--ion-tab-bar-color-selected, var(--ion-color-primary, #3880ff));
    height: 50px;

    padding-left: unset;
    padding-right: unset;
    padding-inline-start: var(--ion-safe-area-left);
    padding-inline-end: var(--ion-safe-area-right);

    a {
        padding-left: var(--ion-safe-area-left);
        padding-right: var(--ion-safe-area-right);
        display: flex;
        width: auto;
        padding-bottom: var(--ion-safe-area-bottom, 0);
        border-top: var(--border);
        background: var(--background);
        text-align: center;
        user-select: none;
        z-index: 10;
        box-sizing: content-box !important;

        --padding-top: 0;
        --padding-end: 2px;
        --padding-bottom: 0;
        --padding-start: 2px;
        max-width: 240px;
        font-size: 10px;


        --ripple-color: var(--color-selected);
        --background-focused-opacity: 1;
        flex: 1 1 0%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        outline: none;
        background: var(--background);
        color: var(--color);

        ion-icon {
            color: #000;
            margin-top: 2px;
            margin-bottom: 2px;
            font-size: 30px;
        }
    }
}