.file-selected {
    position: absolute;
    z-index: 10000;
    background: rgba(#fcf400, 0.7);
    color: #000000;
    text-align: center;
    border-radius: 5px;
}

svg {
    height: 100%;
}
