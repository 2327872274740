.main-header {
    background-color: #fff;
    ion-toolbar {
        &.ion-color-light {
            --ion-color-base: #fff !important;
        }
    }
    .search-icons {
        color: var(--ion-color-dark);
    }
    .item-searchbar {
        --border-width: 0 0 0.55px;
        .searchbar-input-container {
            padding-left: var(--ion-padding);
        }
    }
}
form {
    display: contents;
}
#logo {
    ion-img,
    span {
        display: inline-block;
        vertical-align: middle;
    }
    ion-img {
        width: 150px;
        @media (min-width: 768px) {
            width: 200px;
        }
    }
    @media (min-width: 768px) {
        span {
            font-size: 32px;
        }
    }
}
