.details {
    display: flex;
    flex-flow: column;
    font-size: var(--fs-body);
    width: 100%;

    .grade-name {
        span {
            font-weight: 400;
            display: inline-block;
            color: var(--ion-color-dark);
            padding: calc(var(--ion-margin) / 2) calc(var(--ion-margin) * 2);
            border-radius: 10px;
            background-color: var(--ion-color-yellow);
        }
    }

    .subject-link {
        color: var(--ion-color-primary);
    }

    .subjects,
    .topics {
        //font-size: var(--fs-body-small);
        color: var(--ion-text-color-pale);
    }
}