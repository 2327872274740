ion-slides {
    //height: 100%;
}

.description {
    text-align: center;

    p {
        margin: 0.5em 0;
    }
}


.payment-details {
    padding: 5px 10px;

    &.active {
        border: 1px solid;
    }
    table {
        width: 100%;
        td {
            vertical-align: bottom;

            &:last-child {
                text-align: right;
            }
        }

        tr {
            border-bottom: 1px solid #dddddd;

            &:last-child {
                border-bottom: 0 none;
                font-weight: bold;
            }
        }
    }
}