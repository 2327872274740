@import "../../theme/variables.css";

.inline-label div {
    z-index: 10;
}

.inline-label span {
    font-size: 0.75em;
    display: inline;

    &.aszf-link {
        color: var(--ion-color-primary);
    }
}
