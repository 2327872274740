.twb-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--fs-body-large);
    font-weight: 500;
}
.twb-line {
    height: 1px;
    background: var(--ion-color-step-350, #a6a6a6);
    flex: 1;
    margin: 0 5px;
}