.search-details {
    text-align: center;
    margin: 10px 0;
}

.search-result {
    display: flex;
    justify-content: center;
    align-items: center;

    // .search-result-id {
    //     font-size: 20px;
    //     font-weight: bold;
    //     border-radius: 50%;
    //     background-color: grey;
    //     padding: 5px 10px;
    //     margin-right: 10px;
    // }
    // .search-result-details {
    //     flex-direction: row;
    //     .topic-name {
    //         font-weight: 700;
    //     }
    // }
}
