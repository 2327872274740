.install-notification {
    z-index: 1000;
    padding: 10px;
    margin-bottom: 10px;
    color: #ffffff;
    background: var(--ion-color-success-tint);
    border: solid var(--ion-color-success);
    border-width: 2px 0;
    display: flex;
    justify-content: space-between;
    a {
        color: #ffffff;
        font-weight: bold;
    }
}
.hide-notification {
    padding: 10px 0;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
}
