.result-image {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.rate-icons {
    display: flex;
    margin: 0 auto;
    @media (min-width: 768px) {
        max-width: 50%;
    }
    @media (min-width: 992px) {
        max-width: 33%;
    }
    @media (min-width: 1200px) {
        width: 25%;
    }
    > div {
        margin: 0 calc(var(--ion-margin) / 2);
        cursor: pointer;
    }
}

.bc-question {
    @media (min-width: 768px) {
        margin-bottom: var(--ion-padding);
    }
}
.bc-answer {
    ion-card {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin: 0;
        box-sizing: border-box;
        border: 5px solid transparent !important;
        outline: 1px solid var(--ion-color-yellow);

        .bc-answer-html {
            font-family: Montserrat, sans-serif
        }
    }
}
.fm-editor-equation {
    filter:invert(1);
}
