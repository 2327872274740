.profile-hi {
    font-weight: bold;
}

ion-card-content {
    ion-list {
        max-height: 300px;
        overflow: auto;
    }

    padding: 0 10px 20px 10px;
}

ion-card-title {
    font-size: 18px;
}

ion-item {
    font-size: var(--fs-body);
}

.video-poster {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 72px;
    height: auto;
    padding: 0;
    @media (max-width: 374px) {
        display: none;
    }

    img {
        display: block;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        width: auto;
    }
}

.watch-details {
    flex: 1;
    display: flex;
    flex-direction: column;

    .video-title {
        font-weight: bold;
        font-size: 14px;
    }
}

//.watch-index {
//    display: flex;
//    align-items: center;
//
//    img, svg {
//        width: 32px;
//        margin-right: 5px;
//    }
//}