.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 87px;
    height: 87px;
    padding: 10px;
    margin: 5px auto;

    img {
        display: block;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        width: auto;
    }
}

.details {
    .subject-name {
        font-weight: 700;
    }
}
