#login-container {
    font-weight: bold;
}

ion-note {
    text-align: center;
}

.twb-container {
    text-transform: uppercase;
}

.br-input {
    margin: 10px 0;
}

.pull-right {
    display: flex;
    justify-content: flex-end;
    flex: 1;
}

#btn-login {
    width: 100%;
    margin: 16px auto 0 auto;
}

