.breadcrumb {
    overflow: auto;
    white-space: nowrap;
    padding: calc(var(--ion-padding) / 2);
    background: #fff;
    font-size: 14px;
    margin-bottom: var(--ion-padding);
    span {
        color: var(--ion-color-dark);
        a {
            text-decoration: none;
            color: var(--ion-color-dark);
        }
    }
}
