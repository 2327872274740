video {
    max-width: 100%;
    max-height: 100%;
}

.close {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 32px;
    height: 32px;
    opacity: 0.3;

    &:hover {
        opacity: 1;
    }

    &:before,
    &:after {
        position: absolute;
        left: 15px;
        content: " ";
        height: 33px;
        width: 2px;
        background-color: #333;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}

#video-description {
    // border-top: var(--border-separator);
    // border-bottom: var(--border-separator);
    // * {
    //     //font-family: "Roboto", "Arial", sans-serif !important;
    // }
    padding: var(--ion-margin);
    color: #fff;
}

.btn-desc {
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-size: var(--fs-body);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    cursor: pointer;
    text-align: left;

    ion-icon {
        margin-right: 8px;
    }
}

h1#topic-name {
    text-align: center;
}

.feedback-popover,
.invitation-popover {
    ion-content {
        --offset-bottom: auto !important;
        --overflow: hidden;
        overflow: auto;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
