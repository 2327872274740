:root {
    // --ion-color-primary: #781bff;
    --ion-color-primary: #fff;
    --ion-color-primary-pale: #ddd;
    --ion-color-dark: #293043;
    //--ion-text-color: #030303;
    --ion-text-color: #ffffff;
    --ion-text-color-pale: #dddddd;
    //--ion-card-color: #030303;
    --ion-card-color: #ffffff;
    --fs-body: 1.4rem;
    --fs-body-small: 1.2rem;
    --fs-body-large: 1.6rem;
    --fs-body-x-large: 2rem;
    --color-background: #293043;
    --color-background-pale: #57668f;
    --color-background-pale-light: #c8cbd4;
    --ion-margin: 8px;
    --ion-padding: 16px;
    --border-separator: 1px solid rgba(255, 255, 255, 0.5);
    --border-radius: 10px;
    --ion-color-danger: #b43c3d;
    --ion-color-success: #62be75;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@400;700&display=swap");

ion-item.has-input {
    flex-flow: column;
}

.align-items-top {
    align-items: flex-start;
}

.button-full {
    flex: 1;
    text-transform: uppercase;
}

.height-100 {
    height: 100% !important;
}

.width-100 {
    width: 100% !important;
}

.iconLarge {
    font-size: 100px;
    line-height: 0;
    ion-avatar {
        width: 50vw;
        height: 50vw;
        max-width: 200px;
        max-height: 200px;
        margin: var(--ion-margin) auto;
    }
}

.icon {
    width: 87px;
    height: 87px;
    border-radius: 50%;
    background: var(--ion-color-primary-pale);
    color: var(--ion-color-primary-contrast);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    font-weight: bold;
    margin-right: 10px;
    flex-shrink: 0;
    &.icon-small {
        width: 47px;
        height: 47px;
        font-size: 16px;
    }

    .grade-number {
        color: var(--ion-color-primary-contrast);
    }
}

.text-white {
    color: #fff !important;
}

.text-yellow {
    color: var(--ion-color-yellow) !important;
}

.bg-white {
    background: #fff !important;
}

.has-background {
    .ion-page {
        ion-content {
            // --background: var(--color-background);

            ion-list-header {
                // background-color: #fff;
            }

            .button {
                // text-transform: none;

                &.label {
                    // font-size: 16px;
                    font-weight: 400;
                }
            }
        }
    }

    ion-modal,
    ion-popover {
        ion-title {
            color: #fff;
            background: var(--color-background);
        }
        ion-button {
            --color: #fff;
        }
        ion-content {
            --background: none !important;
        }
    }
}

#header-title {
    line-height: 32px;
    color: #000;
}

#defaultSideMenu,
#defaultChildMenu {
    ion-toolbar {
        color: #fff;
        --color: #fff;
        background: #191d29;
        --background: #191d29;
    }

    ion-content,
    ion-list,
    ion-item,
    ion-item-divider,
    ion-icon {
        color: #fff;
        --color: #fff;
        border-color: #40444d;
        --border-color: #40444d;
        background: #293043;
        --background: #293043;
    }
}

ion-list-header {
    &.list-active {
        background: rgba(41, 48, 67, 0.04);
    }
    span {
        font-size: var(--fs-body);
        // text-transform: uppercase;
    }
    ion-button {
        font-size: var(--fs-body);
        ion-icon {
            margin-right: calc(var(--ion-margin) / 2);
        }
    }
}

.max-height-none {
    max-height: none;
}

ion-list.sublist {
    padding-left: calc(var(--ion-safe-area-left, 0) + 16px);
}

ion-icon.add-new-profile {
    position: absolute;
    right: 0;
    font-size: 32px;
}

ion-button {
    ion-avatar {
        height: 100%;
        img {
            width: auto;
        }
    }
}

.profile-image {
    padding-left: 0;
    ion-avatar {
        width: 100%;
        height: auto;
    }
}

.profile-image {
    .profile-default {
        width: 100%;
        min-height: 75px;
    }
}

ion-list-header {
    ion-button {
        .profile-default {
            font-size: 50px;
            margin: 0 8px 0 5px;
        }
    }
}

form.profile-data {
    ion-select {
        padding: 10px;
        margin-bottom: 8px;
        color: var(--ion-color-dark);
        background: #fff;
        border-radius: 10px;
    }
}
.ion-no-padding {
    ion-select {
        padding: 0 10px;
        color: var(--ion-color-dark);
        background: #fff;
        border-radius: 10px;
    }
}

textarea {
    border: 1px solid rgba(41, 48, 67, 0.3) !important;
    padding-left: calc(var(--ion-padding) / 2) !important;
    padding-right: calc(var(--ion-padding) / 2) !important;
}

ion-card-content,
.card-content-md,
.card-content-ios {
    p {
        margin-bottom: 0.5em;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

p {
    font-size: var(--fs-body);
    margin-top: 0;
    margin-bottom: 0.5rem;
    &:last-child {
        margin-bottom: 0;
    }
}

ion-card-content {
    &.card-content-md,
    &.card-content-ios {
        padding: var(--ion-padding);
    }

    h2 {
        font-size: 18px;
    }

    ion-list {
        max-height: none !important;

        &.list-md,
        &.list-ios {
            padding-top: 0;
        }

        ion-item {
            &::part(native) {
                padding: 0;
            }
            &:last-child {
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
    }
}

ion-router-link {
    cursor: pointer;
    text-decoration: underline;
}

.toplayer {
    z-index: 999;
}

h2 span#price {
    font-size: 24px;
}

hr {
    background-color: #ddd;
}

.button-large {
    height: 2.8em;
}

.clickable {
    cursor: pointer;
}

/* REDESIGN */
@font-face {
    font-family: "Roboto";
    src: url("assets/fonts/Roboto-Regular.ttf");
}

* {
    //font-family: "Roboto", "Arial", sans-serif;
}

.ion-color-white {
    --ion-color-base: var(--ion-color-white) !important;
    --ion-color-base-rgb: var(--ion-color-white-rgb) !important;
    --ion-color-contrast: var(--ion-color-white-contrast, #fff) !important;
    --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-white-shade) !important;
    --ion-color-tint: var(--ion-color-white-tint) !important;
}

ion-router-link a {
    color: var(--ion-color-primary);
}

ion-content {
    > ion-grid {
        margin-bottom: 50px;
    }
}

.card-content-md,
.card-content-ios {
    ion-card-subtitle {
        color: #fff;
        font-weight: 400;
        font-size: var(--fs-body-small);
    }

    h1 {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        ion-icon {
            float: left;
            margin-top: 2px;
            font-size: 20px;
        }
    }

    h3 {
        font-weight: 700;
    }

    ion-item,
    ion-label {
        font-size: var(--fs-body) !important;
    }

    ion-textarea,
    ion-select,
    input.native-input {
        font-size: var(--fs-body) !important;
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid rgba(0, 0, 0, 0.15);
        --padding-start: 5px !important;
        --padding-end: 5px !important;

        &[type="date"] {
            padding: 10px 5px;
        }
    }

    ion-button {
        text-transform: uppercase;
        letter-spacing: 0.5px;
        @media (max-width: 767px) {
            font-size: var(--fs-body);
            white-space: break-spaces;
        }
    }

    ion-label.label-stacked {
        transform: translateY(35%) scale(0.75);
        -webkit-transform: translateY(35%) scale(0.75);
    }
}

#sticky-menu {
    a {
        text-decoration: none;
        font-size: var(--fs-body-small);
    }
}

ion-segment-button {
    &::part(native) {
        margin: 0;
    }
}

.no-border {
    border: none !important;
    --inner-border-width: 0 !important;
}

.watched-videos-wrapper {
    ion-item {
        &::part(native) {
            padding: 0;
            background: none;
        }
    }
    .recommended-topics {
        margin-bottom: 0 !important;
        ion-item {
            border: none !important;
            --inner-border-width: 0 !important;
        }
    }
}

a.ion-color-danger {
    color: var(--ion-color-danger, #b43c3d) !important;
}

.no-min-height {
    --min-height: 0 !important;
}

/* GLASS */
.glass {
    background-color: lighten(#293043, 5%);
    border: 1px solid var(--ion-color-yellow) !important;
    border-radius: var(--border-radius);
    &#sticky-menu {
        a {
            color: var(--text-color-dark);
        }
    }
    ion-item {
        --padding-start: 0;
    }
    input {
        margin-bottom: 8px !important;
        border: none;
        &[type="date"] {
            padding: 8px 10px !important;
        }
    }
    & > ion-list-header {
        ion-button {
            color: var(--ion-color-yellow);
        }
    }
}

ion-app {
    // background: rgb(25, 205, 166);
    // background: linear-gradient(190deg, rgba(25, 205, 166, 1) 50%, rgba(120, 27, 255, 1) 100%);
    background: var(--ion-color-dark);
    ion-content {
        /*
        --background: radial-gradient(150px circle at 0% 0%, #fce563 50%, transparent 51%),
            radial-gradient(250px circle at 10% 10%, var(--text-color-dark) 50%, transparent 51%),
            radial-gradient(200px circle at 20% 25%, #ec4f92 50%, transparent 50.4%),
            radial-gradient(300px circle at 30% 20%, #fce563 50%, transparent 51%);
        */
        --background: var(--ion-color-dark);
        ion-grid {
            ion-row {
                ion-col {
                    > div#content {
                        h2,
                        p {
                            text-shadow: 1px 1px 1px var(--text-color-dark);
                        }
                    }
                    ion-item {
                        --background: none;
                    }
                    > ion-card {
                        background: none;
                        background-color: lighten(#293043, 5%);
                        border: 1px solid var(--ion-color-yellow) !important;
                        ion-card-header {
                            background-color: lighten(#293043, 20%);
                        }
                        &.ion-color-primary {
                            box-sizing: border-box;
                            border: 5px solid var(--ion-color-yellow) !important;
                        }
                    }
                    ion-list {
                        &.md,
                        &.ios {
                            background: none;
                        }
                        &.list-condensed {
                            ion-item {
                                --min-height: unset;
                                ion-label,
                                ul {
                                    margin: 0;
                                }
                            }
                        }
                        ion-item {
                            &::part(native) {
                                background: none;
                            }
                        }
                    }
                    ion-segment {
                        .segment-button-checked {
                            color: var(--ion-color-dark);
                            &::part(indicator) {
                                opacity: 1;
                            }
                            &::part(indicator-background) {
                                background: var(--ion-color-yellow);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    textarea {
        --offset-bottom: auto !important;
        --overflow: hidden;
        overflow: auto;
        &::-webkit-scrollbar {
            width: 3px;
        }
    }

    *::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.5);
    }

    *::-webkit-scrollbar-thumb {
        background: var(--text-color-dark);
    }

    *::-webkit-scrollbar-thumb:hover {
        background: rgba(255, 255, 255, 0.75);
    }
}

/*
 * This adds a scroll bar to ionic alerts
 */
.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
    width: 0.7em;
    display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

ion-card-content {
    //background: lighten(#293043,5%);
    color: #ffffff;
    p,
    span {
        color: #ffffff;
    }
}

input,
textarea {
    margin-bottom: 16px !important;
    padding: 10px !important;
    color: var(--ion-color-dark) !important;
    background-color: #fff !important;
}

ion-card-title {
    color: #fff;
    font-weight: 400;
    font-size: var(--fs-body-large);
}

button.button-native {
    border-radius: var(--border-radius) !important;
}

ion-content {
    input,
    textarea {
        border-radius: 10px !important;
    }
    ion-button {
        &.button-solid {
            margin-left: 0;
            margin-right: 0;
            font-size: var(--fs-body-large);
            text-transform: uppercase;
            color: var(--ion-color-dark);
            background-color: var(--ion-color-yellow);
            --background: var(--ion-color-yellow);
            --background-hover: var(--ion-color-yellow);
            --background-activated: var(--ion-color-yellow);
            --background-focused: var(--ion-color-yellow);
            border-radius: var(--border-radius);
            &::part(native) {
                padding-top: 2px;
                border-radius: var(--border-radius);
            }
        }
        &.button-outline {
            margin-left: 0;
            margin-right: 0;
            font-size: var(--fs-body-large);
            text-transform: uppercase;
            color: var(--ion-color-yellow);
            border: 1px solid var(--ion-color-yellow);
            background: none;
            //--background: var(--ion-color-yellow);
            //--background-hover: var(--ion-color-yellow);
            //--background-activated: var(--ion-color-yellow);
            //--background-focused: var(--ion-color-yellow);
            border-radius: var(--border-radius);
            svg {
                fill: var(--ion-color-yellow);
            }
            &::part(native) {
                padding-top: 2px;
                border: 0 none;
            }
            &:hover {
                opacity: 1;
            }
        }
    }
    .profile-image {
        ion-button {
            &::part(native) {
                padding-top: 0;
                background: none !important;
            }
        }
    }
}

ion-note {
    color: #fff;
}

ion-router-link {
    color: #fff;
}

ion-alert {
    .alert-title.sc-ion-alert-ios,
    .alert-message.sc-ion-alert-ios,
    .alert-button.sc-ion-alert-ios,
    .alert-radio-label.sc-ion-alert-ios {
        color: var(--ion-color-dark);
    }
    [aria-checked=true].sc-ion-alert-ios .alert-radio-inner.sc-ion-alert-ios {
        border-color: var(--ion-color-dark);
    }
    [aria-checked=true].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios {
        color: var(--ion-color-dark);
    }
}

html {
    font-size: 10px;
}

body {
    font-size: var(--fs-body);
}

.form-block-heading {
    padding: 0;
    ion-button {
        span {
            font-size: var(--fs-body-large);
            text-transform: none;
        }
    }
}

.label-stacked.sc-ion-label-ios-h {
    margin-top: 0;
}

.no-text-transform {
    text-transform: none !important;
}

.break-spaces {
    white-space: normal;
}

ion-list-header {
    ion-button {
        margin: 0;
    }
}

ion-button {
    &.ion-color-danger {
        --ion-color-base: #b43c3d !important;
    }
}

.text-x-large {
    font-size: var(--fs-body-x-large);
}

.text-large {
    font-size: var(--fs-body-large);
}

.text-small {
    font-size: var(--fs-body-small);
}

.accordion-title {
    position: relative;
    padding-bottom: var(--ion-margin);
    color: var(--ion-color-yellow);
    ion-icon {
        position: absolute;
        right: 0;
        top: 2px;
    }
}

.accordion-content {
    padding: var(--ion-margin);
}

.sub-topic-on-list {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 180px;
    color: var(--ion-color-primary);
    font-weight: 600;
}

.video-poster {
    margin: 0 10px 0 0;
    img {
        border-radius: 10px;
    }
}

.video-js {
    overflow: hidden;
    border-radius: var(--border-radius);
}

ion-modal {
    * {
        color: var(--ion-color-dark);
    }
}

ion-checkbox {
    --checkmark-color: var(--ion-color-dark);
    --background-checked: var(--ion-color-yellow);
    --border-color-checked: var(--ion-color-yellow);
}

.ion-color-yellow {
    --ion-color-base: var(--ion-color-yellow, #fcf400) !important;
    --ion-color-base-rgb: var(--ion-color-yellow-rgb, 252, 244, 0) !important;
    --ion-color-contrast: var(--ion-color-yellow-contrast, #293043) !important;
    --ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb, 41, 48, 67) !important;
    --ion-color-shade: var(--ion-color-yellow-shade, #fcf400) !important;
    --ion-color-tint: var(--ion-color-yellow-tint, #fcf87e) !important;
    &:not(.toggle-checked) {
        &::part(track) {
            background: #ddd;
        }
    }
}