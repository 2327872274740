.header-message {
    z-index: 1000;
    padding: 10px;
    margin-bottom: 10px;
    color: #ffffff;
    background: var(--ion-color-warning-tint);
    border: solid var(--ion-color-warning);
    border-width: 2px 0;
    display: flex;
    justify-content: space-between;
    a {
        color: #ffffff;
        font-weight: bold;
    }
}

.blue-header-message {
    @extend .header-message;
    background: var(--ion-color-primary-tint);
    border: solid var(--ion-color-primary-shade);
}